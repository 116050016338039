<template>
  <div class="main-box" v-if="shopDetail" @click.stop="closemodel">
    <a-breadcrumb>
      <a-breadcrumb-item
        ><router-link to="/knowledgeMall/shopHome"
          >书课专区</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item v-if="$route.query.name"
        ><a @click="$router.go(-1)">{{
          $route.query.name
        }}</a></a-breadcrumb-item
      >
      <a-breadcrumb-item style="color: #15b7dd">{{
        shopDetail.name
      }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="content">
      <div class="shopimg">
        <img class="img" :src="selectFromObj ? selectFromObj.photo : imgSrc" alt="" />
        <!-- 商品 -->
        <!-- <div
          class="imgList"
          v-if="
            $route.query.productId &&
            shopDetail.photoList &&
            shopDetail.photoList.length > 1
          "
        >
          <img
            :class="{ active: imgSrc == item.photo }"
            :src="item.photo"
            v-for="(item, index) in shopDetail.photoList"
            @click="imgSrc = item.photo"
            :key="index"
            alt=""
          />
        </div> -->
      </div>
      <div class="shopDetail">
        <!-- 名称 -->
        <div class="name-inner">
          <p class="name" v-if="$route.query.productId">{{selectFromObj ? (selectFromObj.title ?selectFromObj.title : selectFromObj.relatedProductName) : shopDetail.name}}</p>
        </div>
        <!-- 价格、领券 -->
        <div class="price-inner">
          <div class="price">
            <p
              class="new"
              v-if="$route.query.productId && !!shopDetail.couponPrice"
            >
              ￥<span
                >{{ managePrice( (selectFromObj ? selectFromObj.price : shopDetail.couponPrice) * 1)[0]
                }}<span class="new_point"
                  >.{{ managePrice( (selectFromObj ? selectFromObj.price : shopDetail.couponPrice) * 1)[1] }}</span
                ></span
              >
            </p>
            <p
              class="new"
              :class="{ newFree: !shopDetail.couponPrice }"
              v-if="$route.query.productId && !shopDetail.couponPrice"
            >
              免费
            </p>
            <p class="old" v-if="$route.query.productId && shopDetail.original && !specsList.length">
              ￥{{ (shopDetail.originalPrice * 1).toFixed(2) }}
            </p>
            <!-- 领券入口 -->
            <div class="coupon-btn" v-if="couponList && couponList.length && shopDetail.couponPrice">
              <img @click.stop="couponFlag = !couponFlag" src="@/assets/image/knowledgeMall/2024090601.png" alt="">
              <div class="couponList" v-show="couponFlag">
                <div class="coupon" :class="{geted:ite.drawId}" v-for="(ite, ind) in couponList" :key="ind">
                  <div class="couponLeft">
                    <!-- 满减券 -->
                    <p class="price" v-if="ite.type == 1">￥
                      <span 
                        :style="[
                          {
                            fontSize:(ite.couponPrice+'').length < 4?'30px':(ite.couponPrice+'').length == 4?'24px':'18px'
                          },
                          {
                            lineHeight:(ite.couponPrice+'').length <= 4?'45px':'37px'
                          }
                        ]
                        ">{{ite.couponPrice}}</span></p>
                    <!-- 折扣券 -->
                    <p class="price" v-else><span>{{ite.discount}}</span>折</p>
                    <p class="condition">{{ite.type == 1?('满'+ite.fullPrice):ite.type == 3?('满'+ite.discountPrice+(ite.discountType==1?'件':'元')):''}}可用</p>
                  </div>
                  <div class="couponRight">
                    <p class="couponName mallHidden">{{ite.name}}</p>
                    <p class="useTime">{{useTime(ite)}}</p>
                    <p class="scope mallHidden">【{{ite.receiveDesc}}】</p>
                  </div>
                  <div v-if="!ite.drawId" @click.stop="receiveCoupon(ite)" class="couponBtn">领取</div>
                  <div v-else class="couponStyle">已领取</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 赠品 -->
        <div class="gift-inner" v-if="giftList.length && shopDetail.couponPrice">
          <div class="side-item">
            <span class="title">
              <img src="@/assets/image/knowledgeMall/2024090603.png" alt="">
              赠送：
            </span>
            <div class="side-r">
              <div class="flex" v-for="item in giftList" :key="'#a' + item.relatedProductId" @click="goShopDetail(item)">
                <img class="photo" :src="item.photo" alt="">
                <div>
                  <p class="name">{{item.title? item.title : item.relatedProductName}}</p>
                  <span class="name">x1</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="schedule-inner" style="align-items: flex-start;" v-if="specsList.length">
          <span class="title">选择规格：</span>
          <div class="list">
            <div class="item" :class="{'item-hover' : item.relatedProductId == relatedProductId}" @click="onSpecsSelected(item)" v-for="(item) in specsList" :key="'#c' + item.relatedProductId">
              <img :src="item.photo" alt="">
              <div class="side-r">
                <p class="name">{{item.title? item.title : item.relatedProductName}}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 配送地址 -->
        <div class="menu" style="align-items: baseline;">
          <span class="side-n">选择配送：</span>
          <div>
            <div class="address">
              <div>
                <img class="icon" src="@/assets/image/knowledgeMall/2024091101.png" alt="">
                <span>{{shopDetail.provice}}</span>
                <span class="space">配送至</span>
                <span class="area" @click="onOpenAddress" :style="{color: (addressText ? '#333333' : '#666666')}">
                  {{addressText ? addressText :'请选择地址'}}
                  <img
                    @click="onOpenAddress"
                    :src="isOpenAddress ? downImg : openImg"
                  />
                </span>
              </div>
              <div class="tips">({{shopDetail.shipments}})</div>
            </div>
            <div class="select-input" >
              <div class="address-box" v-show="isOpenAddress">
                <!-- 自建地址 -->
                <div class="self-address" v-if="addressList.length">
                  <a-select
                    :default-value="feightId"
                    style="width: 300px"
                    @change="handleChange"
                  >
                    <a-select-option
                      :value="item.id"
                      v-for="(item, index) in addressList"
                      :key="index"
                    >
                      {{ item.proviceName }}{{ item.cityName }}{{ item.areaName
                      }}{{ item.address }}
                    </a-select-option>
                  </a-select>
                </div>
                <img
                  class="icon"
                  @click="onOpenAddress"
                  :src="closeImg"
                  alt=""
                />
                <!-- 级联地址选择 -->
                <div class="address-inner">
                  <div class="select">
                    <span
                      @click="getAddress(0)"
                      :class="{ spanHover: addressIndex == 0 }"
                      >{{ provinceName ? provinceName : "请选择" }}</span
                    >
                    <span
                      @click="getAddress(1)"
                      v-show="provinceName"
                      :class="{ spanHover: addressIndex == 1 }"
                      >{{ cityName ? cityName : "请选择" }}</span
                    >
                    <span
                      @click="getAddress(2)"
                      v-show="cityName && regionForm.area.length"
                      :class="{ spanHover: addressIndex == 2 }"
                      >{{ areaName ? areaName : "请选择" }}</span
                    >
                  </div>
                  <div class="region-list">
                    <div
                      class="item"
                      @click="onRegionItem(item)"
                      v-for="item in regionList"
                      :key="item.code"
                    >
                      <span
                        :class="{
                          hoverSpan:
                            item.name == provinceName ||
                            item.name == cityName ||
                            item.name == areaName,
                        }"
                        >{{ item.name }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p v-if="addressText" class="menu">
          <span class="side-n">运&emsp;&emsp;费：</span><span>{{ feightPriceText }}</span>
        </p>
        <div
          v-if="$route.query.productId"
          class="menu"
        >
          <span class="side-n">数&emsp;&emsp;量：</span>
          <div class="count">
            <a-icon class="icon" type="minus" @click="changeCount(-1)" />
            <a-input-number
              id="inputNumber"
              v-model="shopDetail.count"
              :min="1"
            />
            <a-icon class="icon" type="plus" @click="changeCount(1)" />
          </div>
        </div>
        <div class="buyBtns" v-if="!shopDetail.isGift">
          <div
            class="btn car-button"
            @click="joinShopCar"
          >
            <a-icon type="shopping-cart" />
            <p>加入购物车</p>
          </div>
          <div
            class="btn buy-button"
            @click="buyNow"
          >
            <a-icon type="shopping" />
            <p>立即购买</p>
          </div>
        </div>
      </div>
    </div>
    <!-- tabs -->
    <div class="tabs-box">
      <!-- 切换 -->
      <div class="tabs">
        <a
          class="tabsdiv"
          v-if="
            $route.query.productId &&
            (shopDetail.type == 2 || shopDetail.type == 3)
          "
          href="javascript:;"
          @click="tabIndex = 1"
          :class="{'tabs-hover' : tabIndex == 1}"
        >
          <span>商品介绍</span>
        </a>
        <a
          class="tabsdiv"
          v-if="bookMessage()"
          href="javascript:;"
          @click="tabIndex = 2"
          :class="{'tabs-hover' : tabIndex == 2}"
        >
          <span>书籍信息</span>
        </a>
        <a
          class="tabsdiv"
          v-if="productList.length"
          href="javascript:;"
          @click="tabIndex = 3"
          :class="{'tabs-hover' : tabIndex == 3}"
        >
          <span>猜你喜欢</span>
        </a>
      </div>
      <!-- 视图 -->
      <div class="contenttab">
        <!-- 商品介绍 -->
        <div v-if="tabIndex == 1" v-html="shopDetail.pcDetail"></div>
        <!-- 书籍信息 -->
        <div v-else-if="tabIndex == 2">
          <!-- 书籍信息 -->
          <div class="catalogue relatedIntroduction" v-if="shopDetail.book">
            <div class="item" v-if="shopDetail.book.bookName">
              <div class="left">
                <span>书</span>
                <span>名：</span>
              </div>
              <div class="right">
                <div class="span">{{ shopDetail.book.bookName }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.isbnCode">
              <div class="left">ISBN：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.isbnCode }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.bookCname">
              <div class="left">
                <span>从</span>
                <span>书</span>
                <span>名：</span>
              </div>
              <div class="right">
                <div class="span">{{ shopDetail.book.bookCname }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.edition">
              <div class="left">
                <span>版</span>
                <span>次：</span>
              </div>
              <div class="right">
                <div class="span">{{ shopDetail.book.edition }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.press">
              <div class="left">
                <span>出</span>
                <span>版</span>
                <span>社：</span>
              </div>
              <div class="right">
                <div class="span">{{ shopDetail.book.press }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.author">
              <div class="left">
                <span>作</span>
                <span>者：</span>
              </div>
              <div class="right">
                <div class="span">{{ shopDetail.book.author }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.publicationTime">
              <div class="left">出版时间：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.publicationTime }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.authorRegion">
              <div class="left">作者地区：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.authorRegion }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.bookSize">
              <div class="left">开本/尺寸：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.bookSize }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.cipCategory">
              <div class="left">CIP分类：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.cipCategory }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.editor">
              <div class="left">编者：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.editor }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.keyword">
              <div class="left">关键字：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.keyword }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.numberPage">
              <div class="left">页数：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.numberPage }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.numberWord">
              <div class="left">字数：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.numberWord }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.packageCount">
              <div class="left">包册数：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.packageCount }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.price">
              <div class="left">定价：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.price }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.reader">
              <div class="left">读者对象：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.reader }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.recommend">
              <div class="left">编辑推荐：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.recommend }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.stamper">
              <div class="left">印张：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.stamper }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.suit !== null">
              <div class="left">是否套装：</div>
              <div class="right">
                <div class="span">
                  {{
                    shopDetail.book.suit === 0
                      ? "否"
                      : shopDetail.book.suit == 1
                      ? "是"
                      : ""
                  }}
                </div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.texture">
              <div class="left">内文用纸材质：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.texture }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.trademark">
              <div class="left">品牌：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.trademark }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.translator">
              <div class="left">译者：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.translator }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.wrap">
              <div class="left">包装：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.wrap }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 评价 -->
        <div v-else-if="tabIndex == 3">
          <div class="collection-list">
            <div class="collection-item" v-for="item in productList" :key="item.relationId" @click="goShopDetail(item)">
              <img class="cover" :src="item.photo" alt="">
              <div class="info">
                <p class="name">{{item.title? item.title : item.relatedProductName}}</p>
                <p class="price"><i v-show="item.price">¥</i>{{item.price?item.price.toFixed(2):'免费'}}</p>
                <span class="go">
                  查看详情
                  <img src="@/assets/image/knowledgeMall/circularright2.png" alt="">  
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Shopwarning :visible="visible" :fontText="fontText" />
    <Sidebar ref="sidebar"/>
  </div>
</template>

<script>
// import forTree from '@/components/forTree/index.vue'
import Sidebar from "@/components/sidebar.vue";
export default {
  // 可用组件的哈希表
  components: { Sidebar },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      couponFlag: false, // 优惠券弹框状态
      clientWidth: 0, // 视口宽度
      openImg: require("@/assets/image/selected/open.png"),
      downImg: require("@/assets/image/selected/down.png"),
      closeImg: require("@/assets/image/selected/close.png"),
      isOpenAddress: false,
      imgSrc1: require("@/assets/image/temporary/Group4627.png"),
      swiperLeft: false,
      swiperRight: false,
      feightPriceText: "",
      code: 0,
      regionForm: {
        province: [], // 省数据
        city: [], // 市数据
        area: [], // 区数据
      },
      selectFromObj: null, // 已选规格商品
      relatedProductId:'', //所选规格商品id
      giftList:[], // 赠品
      specsList:[], // 规格
      productList: [], // 推荐商品
      provinceName: "", // 省名称
      cityName: "", // 市名称
      areaName: "", // 区名称
      regionList: [],
      feightId: "", // 运费模板id
      feightItem: {}, // 运费模板
      addressText: "", // 地址拼接
      addressIndex: 0, // 地址选择下标
      addressList: [], // 收货地址列表
      classList: [], // 商品-班期list
      visible: false,
      fontText: "", // 文本
      tabIndex: 1,
      formData: {},
      mallClass: false, // 更多期数状态
      imgSrc: require("@/assets/image/temporary/pic_nav.jpg"),
      tabMenu: ["全部", "213"],
      couponList: null, // 优惠券列表
      swiperOptions: {
        slidesPerView: 3.8,
        centeredSlides: false,
        centeredSlidesBounds: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          disabledClass: "my-button-disabled",
        },
        // Some Swiper option/callback...
      },
      // 包含课程列表
      courseList: [],
      
      shopDetail: null,
    };
  },
  // 事件处理器
  methods: {
    // 规格选择
    onSpecsSelected(e) {
      this.selectFromObj = e
      this.relatedProductId = e.relatedProductId
    },
    bookMessage() {
      let flag = false;
      if(this.shopDetail.book){
        let obj = JSON.parse(JSON.stringify(this.shopDetail.book))
        delete obj.id;
        delete obj.productId;
        Object.values(obj).map(item=>{
          if(!!item){
            flag = true;
          }
        })
      }
      return this.$route.query.productId && this.shopDetail.isBook && flag;
    },
    closemodel(){
      if(this.couponFlag) {
        this.couponFlag = false;
      }
    },
    useTime(ite) {
      if(ite.times == 2) {
        if(ite.startTime && ite.lapseTime) {
          return ite.startTime.split(' ')[0] + '-' + ite.lapseTime.split(' ')[0]
        }
      }else if(ite.times == 3) {
        if(ite.lapseTime) {
          return '至'+ ite.lapseTime.split(' ')[0]
        }
        if(ite.days) {
          return '领取后'+ite.days+'天内可用'
        }
      }else if(ite.times == 1) {
        return '不限'
      }
    },
    // 加入购物车
    joinShopCar(){
      // this.feightPriceText == "不配送"
      if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/loginIndex?type=2');
			}
      this.$ajax({
				url:'/hxclass-pc/pc-mall/shop/save',
				method:'post',
				params:{
					count:this.shopDetail.count, // 数量
					couponCode:'', // 优惠劵编码
					openTimeId:'', // 开班设置id
          price: (this.selectFromObj?this.selectFromObj.price : this.shopDetail.couponPrice), // 价格  优惠价格-还没有计算优惠券
          productId: (this.selectFromObj?this.selectFromObj.relatedProductId : this.shopDetail.productId), // 商品id
					type:this.shopDetail.type, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考
					userNo:this.$store.state.userInfo.userId, // 用户
				}
			}).then(res=>{
				if(res.code == 200 && res.success){
          this.$refs['sidebar'].getShopList()
					return this.$message.success('添加购物车成功');
				}else{
					this.$message.error(res.message)
				}
      })
    },
    // 立即购买
    buyNow(){
      if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/loginIndex?type=2');
			}
      if(this.feightPriceText == "不配送"){
				return this.$message.error('此商品不支持在当前地区销售');
			}
      let list = [];
      let giftList = []; // 赠品处理
      // 赠品数据处理
      this.giftList.map(item=>{
        giftList.push({
          productId: item.relatedProductId, // 商品id
          count: this.shopDetail.count, // 数量")
          openTimeId:'', // 班级id
          type:item.productType, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
          province:'', // 省
          city:'',// 市
          noGift:1, // 是否为赠品 0否1是t
        })
      })
			list.push({
        productId: (this.selectFromObj ? this.selectFromObj.relatedProductId : this.shopDetail.productId), // 商品id
				count:this.shopDetail.count, // 数量")
				openTimeId:'', // 班级id
				type:this.shopDetail.type, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
				province:'', // 省
				city:'',// 市
				noGift:0, // 是否为赠品 0否1是t
				productList:giftList
			})
      this.$store.commit('updateCouponList',null);
			this.$store.commit('updatelist',list);
			this.$store.commit('updateType',1);
			this.$store.commit('updateShortInvoiceId',null);
			this.$store.commit('updateInvoiceId',null);
			this.$store.commit('updateAddress',null);
			this.$router.push('/order/confirmOrder')
    },
    // 价格处理
    managePrice(con) {
      let txt = (con * 1).toFixed(2).split(".");
      return txt;
    },
    // 级联查询省市区
    getAddress(index) {
      this.addressIndex = index;
      this.regionList =
        index == 0
          ? this.regionForm.province
          : index == 1
          ? this.regionForm.city
          : this.regionForm.area;
    },
    // 点击 省/市
    onRegionItem(e) {
      // 点击的地区code
      this.code = e.code;
      if (this.addressIndex == 0) {
        // 存省名称
        this.addressIndex = 1;
        this.provinceName = e.name;
        this.getRegion();
        this.cityName = "";
        this.areaName = "";
        this.addressText = e.name;

        this.feightItem.provice = e.code;
      } else if (this.addressIndex == 1) {
        // 存市名称
        if (e.code != 120200) {
          // 天津市郊县无城市
          this.addressIndex = 2;
        }
        this.cityName = e.name;
        this.getRegion();
        this.areaName = "";
        this.addressText = this.provinceName + e.name;

        // 点击到市时即可计算运费
        this.feightItem.city = e.code;
        this.feightPrice();
      } else if (this.addressIndex == 2) {
        // 存区名称
        this.areaName = e.name;
        this.addressText = this.provinceName + this.cityName + e.name;
      }
    },
    // 查省市区
    getRegion() {
      this.$ajax({
        url: "/hxclass-pc/province-pc/pc/province/" + this.code,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (res.data.length) {
            if (this.addressIndex == 0) {
              // 存省
              this.regionForm.province = res.data;
            } else if (this.addressIndex == 1) {
              // 存市
              this.regionForm.city = res.data;
            } else if (this.addressIndex == 2) {
              // 存区
              this.regionForm.area = res.data;
            }
            this.regionList = res.data;
          } else {
            // 上层函数onRegionItem里已赋三级地区值，如果三级没城市的时候重置一下
            this.areaName = "";
            this.addressIndex = 1;
            this.regionForm.area = [];
          }
        }
      });
    },
    font(e) {
      return e.replace(/\<[^>]+>/g, "");
    },
    changeMask(e) {
      if (e == 1) {
        this.swiperLeft = true;
      } else if (e == 2) {
        this.swiperLeft = false;
      } else if (e == 3) {
        this.swiperRight = true;
      } else if (e == 4) {
        this.swiperRight = false;
      }
    },
    routerPush(e) {
      if (e.type == 1) {
        this.$router.push(
          "/knowledgeMall/courseDetail?productId=" +
            this.$AES.encode_data(e.productId + "") +
            "&code=" +
            e.code
        );
      } else if (e.type == 2 || e.type == 3) {
        this.$router.push(
          "/knowledgeMall/curriculumDetail?productId=" +
            this.$AES.encode_data(e.productId + "")
        );
        this.getShopDetail();
      } else if (e.type == 4) {
        this.$router.push(
          "/knowledgeMall/examDetail?productId=" +
            this.$AES.encode_data(e.productId + "")
        );
      }
    },
    handleChange(value) {
      // console.log(value);
      // 遍历寻找所选的地址
      this.addressList.forEach((e) => {
        if (e.id == value) {
          this.addressText = e.proviceName + e.cityName + e.areaName;
        }
      });
      let arr = this.addressList.filter((item) => {
        return item.id == value;
      });
      this.feightId = value;
      this.feightItem = arr[0];
      this.feightPrice();
    },
    // 改变商品数量
    changeCount(i) {
      if (i < 0) {
        if (this.shopDetail.count != 1) {
          this.$set(this.shopDetail, "count", this.shopDetail.count + i);
        }
      } else {
        this.$set(this.shopDetail, "count", this.shopDetail.count + i);
      }
      this.feightPrice();
    },
    // 跳转商品详情
    goShopDetail(item){
      if (item.productType == 1) {
        this.$router.push(
          "/knowledgeMall/courseDetail?productId=" +
            this.$AES.encode_data(item.relatedProductId + "") +
            "&code=" +
            item.code
        );
      } else if (
        item.productType == 2 ||
        item.productType == 3
      ) {
        this.$router.push(
          "/knowledgeMall/curriculumDetail?productId=" +
            this.$AES.encode_data(item.relatedProductId + "")
        );
      } else if (item.productType == 4) {
        this.$router.push(
          "/knowledgeMall/examDetail?productId=" +
            this.$AES.encode_data(item.relatedProductId + "")
        );
      }
    },
    // 获取商品详情
    getShopDetail() {
      this.$ajax({
        url: "/hxclass-pc/pc-mall/pc/mall/product-detail",
        method: "get",
        params: {
          productId: this.$AES.decode_data(this.$route.query.productId),
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (!res.data) {
            return this.$message.error("该商品已下架");
          }
          res.data.count = 1;
          if (this.$store.state.userInfo.isLogin) {
            this.getAddressList();
          }
          this.shopDetail = res.data;
          if (res.data.photoList) {
            res.data.photoList = res.data.photoList.filter((item) => {
              return item.type == 2;
            });

            this.imgSrc = res.data.photoList[0].photo;
          }
          // 获取优惠券列表
          this.getCouponList(1);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    
    selectTime(){
      //时间戳
      // let time = +new Date();
      //时间戳
      let time = new Date().getTime();
      var date = new Date(time + 8*3600*1000); // 增加8小时
      return date.toJSON().substr(0, 19).replace('T', ' ');
    },

    // 领取优惠券
    receiveCoupon(e) {
      if(!this.$store.state.userInfo.userId){
        return this.$router.push('/login/loginIndex?type=2')
      }
      if(this.receiveCouponflag){
        return 
      }
      this.receiveCouponflag = true;
      this.$ajax({
        url:'/hxclass-pc/pc-mall/app/save-draw',
        method:'post',
        params:{
          couponId:e.couponId
        }
      }).then(res=>{
        this.receiveCouponflag = false;
        if(res.code == 200 && res.success){
          this.$message.success('领取成功');
          this.getCouponList();
        }else{
          this.$message.error(res.message);
        }
      })
    },

    // 获取优惠券列表
    getCouponList(e) {
      this.$ajax({
        url: "/hxclass-pc/pc-mall/product/coupon",
        method: "get",
        params: {
          productId: this.$AES.decode_data(this.$route.query.productId + ""), //	购物车商品 1,2,3,4
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.couponList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    onOpenAddress() {
      this.isOpenAddress = !this.isOpenAddress;
    },
    // 获取收货地址列表
    getAddressList() {
      this.$ajax({
        url: "/hxclass-pc/ship/address/list",
        method: "get",
        params: {
          pageNum: 1,
          pageSize: 10000,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (res.data.records.length) {
            this.addressList = res.data.records;
            let firstAddress = res.data.records[0];
            this.feightId = firstAddress.id;
            this.feightItem = firstAddress;
            this.addressText =
              firstAddress.proviceName +
              firstAddress.cityName +
              (firstAddress.areaName?firstAddress.areaName:'');
            this.feightPrice();
          }
        }
      });
    },
    // 计算运费
    feightPrice() {
      this.$ajax({
        url: "/hxclass-pc/pc-mall/pc/product-freight",
        method: "get",
        params: {
          city: this.feightItem.city, // 市编码
          count: this.shopDetail.count, // 数量
          freightId: this.shopDetail.freightId, // 运费模板id
          province: this.feightItem.provice, // 省编码
          weight: this.shopDetail.weight, // 重量
          total: Number(this.shopDetail.couponPrice) * this.shopDetail.count
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (res.data.code == 1) {
            this.feightPriceText = "包邮";
          } else if (res.data.code == 2) {
            this.feightPriceText = "不配送";
          } else if (res.data.code == 3) {
            this.feightPriceText = "￥" + res.data.price.toFixed(2);
          }
        }
      });
    },

    // 查询销售关系商品
    productRelation(){
      this.$ajax({
        url: "/hxclass-pc/pc-mall/productRelation/list/client/" + this.$AES.decode_data(this.$route.query.productId + ""),
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 赠品数据处理
					this.giftList = res.data.filter(item=>{
						return item.relationType == 1;
					})
					
					// 规格商品处理
					this.specsList = res.data.filter(item=>{
						return item.relationType == 4;
					})
					if(this.specsList.length){
						this.relatedProductId = this.$AES.decode_data(this.$route.query.productId)
					}
					
					// 推荐商品处理
					this.productList = res.data.filter(item=>{
						return item.relationType == 5;
					})
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.clientWidth = document.body.clientWidth;
    // 查询全部省
    this.getRegion();
    if (this.$route.query.productId) {
      this.getShopDetail();
      // 查询销售关系
      this.productRelation()
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    clientWidth(newVal,oldVal){
      if(newVal<16000){
        this.$set(this.swiperOptions,'slidesPerView',3.7);
      }else{
        this.$set(this.swiperOptions,'slidesPerView',3.8);
      }
    },
    "$route.query.productId"(newVal,oldVal){
      this.getShopDetail();
      // 获取优惠券列表
      this.getCouponList();
      this.productRelation();
    }
  },
};
</script>

<style lang="less" scoped>
.relatedIntroduction {
  display: flex;
  flex-wrap: wrap;
  padding: 0 100px;
  .item {
    width: 430px;
    margin-right: 60px;
    display: flex;
    margin-bottom: 25px;
    .left {
      // width: 114px;
      text-align: right;
      font-size: 16px;
      font-family: PingFangMedium;
      color: #333333;
      line-height: 24px;
    }
    .right {
      flex: 1;
      font-size: 16px;
      font-family: PingFangMedium;
      color: #333333;
      line-height: 24px;
    }
  }
  @media screen and (max-width:16000px) {
    .item {
      width: 400px;
    }
  }
}
.buyBox {
  text-align: center;
  width: 257px;
  padding-top: 74px;
  .title {
    font-size: 25px;
    font-family: PingFangMedium;
    color: #333333;
    line-height: 25px;
    letter-spacing: 2px;
    margin-bottom: 41px;
  }
  .price {
    height: 58px;
    text-align: center;
    .new {
      font-size: 18px;
      font-family: PingFangMedium;
      color: #EC6C01;
      line-height: 18px;
      span {
        font-size: 31px;
      }
    }
    .old {
      margin-top: 2px;
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #666666;
      line-height: 19px;
    }
  }
  .button {
    cursor: pointer;
    background: #15b7dd;
    box-shadow: 0px 5px 5px 1px rgba(206, 206, 206, 0.25);
    border-radius: 26px 26px 26px 26px;
    padding: 4px 36px;
    font-size: 23px;
    font-family: PingFangMedium;
    color: #ffffff;
    line-height: 34px;
    .icon {
      color: #ffffff;
      font-size: 23px;
      margin-right: 6px;
    }
  }
}
::v-deep.swiper-container {
  // margin: 0;
  width: 100%;
  padding: 0 50px;
}
::v-deep.swiper-button-next {
  position: absolute;
  right: 0px;
  top: 187px;
  width: 40px;
  height: 40px;
  background-image: url("~@/assets/image/knowledgeMall/circularright.png");
  background-repeat: no-repeat;
  background-size: 40px;
  .rightWrite {
    position: absolute;
    right: 0;
    top: -187px;
    z-index: 0;
    width: 60px;
    height: 500px;
    background: #fafafa;
  }
  img {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    // border-bottom-left-radius: 22px;
    // border-top-left-radius: 22px;
  }
}
.swiper-button-next:hover {
  background-image: url("~@/assets/image/knowledgeMall/circularright2.png");
}
::v-deep.swiper-button-next:after {
  opacity: 0;
}
::v-deep.swiper-button-prev:after {
  opacity: 0;
}
::v-deep.swiper-button-prev {
  position: absolute;
  left: 0;
  top: 187px;
  width: 40px;
  height: 40px;
  background-image: url("~@/assets/image/knowledgeMall/circularleft.png");
  background-repeat: no-repeat;
  background-size: 40px;
  .leftWrite {
    position: absolute;
    left: 0;
    top: -187px;
    z-index: 0;
    width: 60px;
    height: 1000px;
    background: #fafafa;
  }
  img {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    // border-bottom-left-radius: 22px;
    // border-top-left-radius: 22px;
  }
}
.swiper-button-prev:hover {
  background-image: url("~@/assets/image/knowledgeMall/circularleft2.png");
}
.main-box {
  padding-top: 80px;
  padding-bottom: 60px;
  .content {
    margin-top: 24px;
    padding: 40px;
    border-radius: 5px;
    background: #ffffff;
    display: flex;
    .shopimg {
      .img {
        width: 496px;
        height: 496px;
        border-radius: 5px;
      }
      @media screen and (max-width:16000px) {
        .img {
          width: 426px;
          height: 426px;
        }
      }
      .imgList {
        width: 426px;
        overflow: auto;
        display: flex;
        margin-top: 12px;
        padding-bottom: 3px;
        .active {
          border: 1px solid #15b7dd;
        }
        img {
          width: 60px;
          height: 60px;
          margin-right: 16px;
          border-radius: 2px;
          box-sizing: border-box;
          cursor: pointer;
        }
        @media screen and (max-width:16000px) {
          img {
            width: 52px;
            height: 52px;
          }
        }
        img:last-child {
          margin: 0;
        }
      }
    }
    .shopDetail {
      margin-left: 30px;
      flex: 1;
      position: relative;
      .flex-between{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .name-inner{
        .name {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 24px;
          color: #333333;
          line-height: 36px;
        }
      }
      .price-inner{
        .price{
          color: #FF452D;
          display: flex;
          align-items: center;
          .new{
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            line-height: 25px;
            span{
              font-size: 32px;
              line-height: 48px;
            }
          }
          .old{
            font-size: 16px;
            font-family: PingFangMedium;
            text-decoration: line-through;
            color: #999999;
            line-height: 21px;
            margin-left: 9px;
          }
          .coupon-btn{
            position: relative;
            margin-left: 10px;
            img{
              width: 53px;
              cursor: pointer;
            }
            .couponList{
              position: absolute;
              z-index: 10;
              top: 36px;
              left: -150px;
              background: #FFFFFF;
              border-radius: 5px;
              box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.1600);
              padding: 30px 20px 16px;
              .coupon{
                width: 322px;
                margin-bottom: 16px;
                padding-right: 15px;
                height: 80px;
                display: flex;
                align-items: center;
                background-image: url(~@/assets/image/knowledgeMall/coupon_1.png);
                background-size: 100% 100%;
                .couponLeft{
                  padding-right: 20px;
                  width: 93px;
                  text-align: center;
                  .price{
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    font-size: 16px;
                    font-family: PingFangRegular;
                    font-weight: 300;
                    color: #3A3330;
                    line-height: 37px;
                    span{
                      font-size: 30px;
                      line-height: 45px;
                      font-family: PingFangMedium;
                    }
                  }
                  .condition{
                    margin-top: -5px;
                    width: 80px;
                    font-size: 10px;
                    font-family: PingFangRegular;
                    font-weight: 400;
                    color: #3A3330;
                    line-height: 15px;
                    transform: scale(0.9);
                  }
                }
                .couponRight{
                  flex: 1;
                  .couponName{
                    margin-left: -10px;
                    font-size: 14px;
                    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                    font-weight: 500;
                    color: #333333;
                    line-height: 14px;
                  }
                  .useTime{
                    margin-left: -10px;
                    margin-top: 7px;
                    font-size: 12px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #999999;
                    line-height: 8px;
                    transform: scale(0.9);
                  }
                  .scope{
                    margin-top: 8px;
                    font-size: 10px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #842C17;
                    line-height: 10px;
                  }
                }
                .couponBtn{
                  cursor: pointer;
                  background: linear-gradient(134deg, #FFE4C2 0%, #FFD39F 100%);
                }
                .couponStyle{
                  background: linear-gradient(134deg, #FFE4C2 0%, #FFD39F 100%);
                }
                .couponBtn,
                .couponStyle{
                  text-align: center;
                  width: 60px;
                  height: 25px;
                  border-radius: 2px;
                  font-size: 14px;
                  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                  font-weight: 500;
                  color: #333333;
                  line-height: 25px;
                }
              }
              .coupon:last-child{
                margin-bottom: 0;
              }
              .geted{
                opacity: 0.5;
                // background-image: url(~@/assets/image/knowledgeMall/coupon_2.png);
              }
            }
          }
        }
        .label-list{
          display: flex;
          .label-item{
            display: inline-block;
            font-size: 12px;
            color: #15B7DD;
            padding: 4px 6px;
            margin-right: 10px;
            background: rgba(21,183,221,0.1);
            border-radius: 2px;
          }
        }
        .describe {
          font-size: 14px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #1e97b4;
          line-height: 21px;
        }
      }
      .gift-inner{
        background: #F6F6FC;
        border-radius: 2px 2px 2px 2px;
        padding: 14px 12px;
        margin-top: 8px;
        .side-item{
          display: flex;
          align-items: flex-start;
          margin-bottom: 10px;
          .title{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            line-height: 21px;
            margin-right: 10px;
            img{
              position: relative;
              bottom: 2px;
              width: 13px;
            }
          }
          .side-r{
            .name{
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #666666;
              line-height: 21px;
            }
            .flex{
              display: flex;
              margin-bottom: 10px;
              cursor: pointer;
              .photo{
                width: 40px;
                border-radius: 2px;
                margin-right: 4px;
                background-repeat: no-repeat;
                object-fit: cover;
              }
            }
            .flex:last-child{
              margin-bottom: 0;
            }
          }
        }
        .side-item:last-child{
          margin-bottom: 0;
        }
      }
      .schedule-inner{
        margin-top: 14px;
        display: flex;
        align-items: baseline;
        .title{
          display: inline-block;
          min-width: 80px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 21px;
        }
        .list{
          overflow: hidden;
          .item{
            float: left;
            display: flex;
            align-items: center;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            width: 286px;
            color: #333333;
            line-height: 21px;
            padding: 4px;
            margin-right: 12px;
            margin-bottom: 8px;
            border-radius: 2px 2px 2px 2px;
            border: 1px solid #CCCCCC;
            cursor: pointer;
            img{
              border-radius: 4px;
              width: 40px;
              height: 40px;
              margin-right: 12px;
              background-repeat: no-repeat;
              object-fit: cover;
            }
            .side-r{
              flex: 1;
              font-family: PingFang SC, PingFang SC;
              .name{
                max-width: 219px;
                font-weight: 400;
                font-size: 14px;
                color: #333333;
                line-height: 21px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
          .item:nth-child(2n){
            margin-right: 0;
          }
          .item-hover{
            position: relative;
            color: #00BDE9;
            border: 1px solid rgba(0,189,233,0.1);
            background: rgba(0,189,233,0.1);
            &::after{
              content: '';
              position: absolute;
              right: 0;
              bottom: 0;
              width: 22px;
              height: 17px;
              z-index: 3;
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-image: url("~@/assets/image/knowledgeMall/2024090604.png");
            }
          }
        }
        .address{
          font-family: PingFang SC, PingFang SC;
          padding: 4px 12px;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 21px;
          background: #F6F6FC;
          border-radius: 2px 2px 2px 2px;
          margin-top: 4px;
          img{
            position: relative;
            bottom: 2px;
            width: 9px;
            margin-right: 4px;
          }
        }
      }
      .menu {
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 21px;
        display: flex;
        margin-top: 16px;
        align-items: center;
        .side-n{
          min-width: 80px;
        }
        .address{
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 21px;
          .icon{
            width: 9px;
            margin-bottom: 2px;
            margin-right: 4px;
          }
					.tips{
            margin-top: 4px;
						font-weight: 400;
            font-size: 12px;
            color: #999999;
            line-height: 18px;
					}
					.space{
						margin: 0 16px;
            color: #666666;
					}
          .area{
            cursor: pointer;
            img{
              width: 18px;
            }
          }
				}
        .select-input {
          position: relative;
          /deep/ .ant-input {
            height: 41px;
            cursor: pointer;
          }
          .icon {
            width: 20px;
            position: absolute;
            right: 12px;
            top: 10px;
            cursor: pointer;
          }
          .address-box {
            position: absolute;
            z-index: 100;
            padding: 16px;
            top: 10px;
            left: 0;
            width: 460px;
            background: #fff;
            border: 1px solid #3dd1eb;
            .self-address {
              display: flex;
              justify-content: space-between;
            }
            .address-inner {
              margin-top: 10px;
              .select {
                display: flex;
                border-bottom: 1px solid #15b7dd;
                > span {
                  display: inline-block;
                  position: relative;
                  top: 1px;
                  color: #666666;
                  margin-right: 10px;
                  width: auto;
                  max-width: calc(100% / 3);
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-weight: 400;
                  font-size: 14px;
                  padding: 0 7px;
                  border: 1px solid #ebebeb;
                  border-bottom: 1px solid #15b7dd;
                  cursor: pointer;
                }
                .spanHover {
                  color: #333333;
                  border: 1px solid #15b7dd;
                  border-bottom: 1px solid #ffffff;
                }
              }
              .region-list {
                margin-top: 8px;
                display: flex;
                flex-wrap: wrap;
                .item {
                  width: calc(100% / 3);
                  > span {
                    display: inline-block;
                    width: auto;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-height: 24px;
                    padding: 2px 4px;
                    color: #333333;
                    cursor: pointer;
                  }
                  .hoverSpan {
                    color: #ffffff;
                    background: #15b7dd;
                    border-radius: 4px;
                  }
                }
              }
            }
          }
        }
        .count {
          width: 110px;
          height: 32px;
          display: flex;
          align-items: center;
          padding: 0 6px;
          border-radius: 5px;
          border: 1px solid #E6E6E6;
          .icon {
            font-size: 14px;
            color: #666666;
          }
          ::v-deep.ant-input-number-handler-wrap {
            display: none;
          }
          ::v-deep.ant-input-number {
            height: 32px;
            width: 70px;
            border: 1px solid #E6E6E6;
            margin: 0 10px;
            border-radius: 0;
          }
          ::v-deep.ant-input-number-input {
            height: 32px;
            line-height: 32px;
            text-align: center;
          }
          ::v-deep.ant-input-number:focus {
            box-shadow: none !important;
          }
        }
      }
      .buyBtns{
        position: absolute;
        bottom: 0;
        right: 0;
        margin-top: 20px;
        justify-content: right;
        .btn{
          width: 142px;
          height: 46px;
          font-weight: 500;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          overflow: hidden;
          color: #ffffff;
          cursor: pointer;
          margin-left: 16px;
          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: -100%;
            background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, .5), transparent);
            transition: all .5s;
          }
          p {
            margin-left: 5px;
          }
          &:hover::before {
            left: 100%;
          }
        }
        // 购物车按钮
        .car-button {
          background: #15b7dd;
        }
        // 购买按钮
        .buy-button{
          background: #FF7A00;
        }
      }
    }
  }
}
.tabs-box {
  margin-top: 16px;
  background-color: #ffffff;
  position: relative;
  padding: 0 27px;
  border-radius: 5px;
  .tabs {
    height: 62px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin-bottom: 25px;
    .tabsdiv {
      font-size: 20px;
      width: calc(100% / 3);
      font-family: PingFangMedium;
      position: relative;
      color: #666;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .tabs-hover{
      color: #00BDE9;
      &::after{
        content: '';
        position: absolute;
        bottom: -21px;
        width: 22px;
        height: 3px;
        background: #00BDE9;
        border-radius: 2px 2px 2px 2px;
      }
    }
  }
}

.contenttab {
  overflow: hidden;
  /deep/img {
    max-width: 100%;
  }
  .collection-list{
    overflow: hidden;
    .collection-item{
      float: left;
      display: flex;
      border-radius: 5px 5px 5px 5px;
      border: 1px solid rgba(0,0,0,0.2);
      width: calc((100% - 20px) / 2);
      margin-right: 20px;
      margin-bottom: 20px;
      padding: 12px;
      cursor: pointer;
      .cover{
        width: 140px;
        height: 80px;
        background-repeat: no-repeat;
        object-fit: cover;
      }
      .info{
        flex: 1;
        position: relative;
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .name{
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #333333;
          line-height: 26px;
        }
        .price{
          font-weight: 400;
          font-size: 18px;
          color: #EC6C01;
          line-height: 15px;
          i{
            font-style: normal;
            font-size: 12px;
          }
        }
        .go{
          position: absolute;
          right: 0;
          top: 40%;
          font-weight: 500;
          font-size: 16px;
          color: #666666;
          line-height: 24px;
          text-align: left;
          img{
            display: inline-block;
            width: 15px;
          }
        }
      }
    }
    .collection-item:nth-child(2n){
      margin-right: 0;
    }
  }
}

/* 整个滚动条 */
::-webkit-scrollbar {
    /* 对应纵向滚动条的宽度 */
    width: 10px;
    /* 对应横向滚动条的宽度 */
    height: 5px;
}

/* 滚动条上的滚动滑块 */
::-webkit-scrollbar-thumb {
    background-color: @theme;
    border-radius: 32px;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
    background-color: #eee1e1;
    border-radius: 32px;
}
</style>
