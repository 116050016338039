import { render, staticRenderFns } from "./curriculumDetail.vue?vue&type=template&id=056c1609&scoped=true&"
import script from "./curriculumDetail.vue?vue&type=script&lang=js&"
export * from "./curriculumDetail.vue?vue&type=script&lang=js&"
import style0 from "./curriculumDetail.vue?vue&type=style&index=0&id=056c1609&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "056c1609",
  null
  
)

export default component.exports